import contact from "./lib/contact";
import rebox from "./lib/rebox";
import { toggleBelowFold, smoothScroll } from "./lib/scroll";

$(document).ready(function () {
    contact("#contact-form");
    rebox(".headstart-nodetypes-thumbimage");
    toggleBelowFold();
    smoothScroll('a[href^="#"]', {offset: 0})
});
